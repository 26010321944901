// Slideshow
import { Splide } from '@splidejs/splide';
import elementWithinViewport from 'element-within-viewport';

const windowHeight = window.innerHeight;
const threshold = windowHeight * -0.45; // scroll tolerance
const toggleClassName = 'is-active';
const galleryClassName = 'splide';

// this padding calculation is necessary to set correct alignment of gallery
// combined with gallery's viewport-width slide effect:
let galleryPadding = '';
let headInner = document.querySelector('.page-head__inner');
if (typeof headInner === 'undefined' || headInner === null) {
  headInner = document.querySelector('.slide__inner');
}
if (typeof headInner !== 'undefined' && headInner != null) {
  galleryPadding = window.getComputedStyle(
    document.querySelectorAll('.page-head__inner, .slide__inner')[0],
  ).paddingLeft;
}

const options = {
  direction: 'ltr',
  autoWidth: true,
  autoHeight: true,
  gap: '24px',
  pagination: false,
  drag: 'free' as const,
  speed: 1000,
  padding: galleryPadding,
  arrows: false,
  easing: 'cubic-bezier(0.5, 1.3, 0.46, 1)',
  rewind: true,
};

const initSplide = (element: HTMLElement) => {
  if (!element) {
    return;
  }

  const splide = new Splide(element, options).mount();

  // attach events to custom buttons
  element
    .querySelectorAll('.js-slide-controls button')?.[1]
    ?.addEventListener('click', () => {
      splide.go('+1');
    });

  element
    .querySelectorAll('.js-slide-controls button')?.[0]
    ?.addEventListener('click', () => {
      splide.go('-1');
    });

  const descriptionOpener = element.querySelector('.js-description-opener');
  descriptionOpener?.addEventListener('click', () => {
    descriptionOpener?.parentElement?.classList.toggle('is-open');
  });

  const headlineWidth = descriptionOpener?.getBoundingClientRect().width || 0;
  if (headlineWidth > 0) {
    descriptionOpener?.setAttribute('style', `width: ${headlineWidth + 30}px`);
  }
};

const elms = document.getElementsByClassName(
  'splide',
) as HTMLCollectionOf<HTMLElement>;
if (elms) {
  for (let i = 0; i < elms.length; i += 1) {
    initSplide(elms[i]);
  }
}

const handleNavigationClicks = () => {
  const $galleries = document.querySelectorAll(
    `.${galleryClassName}`,
  ) as NodeListOf<HTMLElement>;
  if ($galleries.length) {
    $galleries.forEach(($gallery, i: number) => {
      elementWithinViewport($gallery, {
        threshold,
        onEnter: ($element: HTMLElement) => {
          $element?.parentElement
            ?.querySelectorAll('.js-navi-item')
            [i]?.classList.add(toggleClassName);
        },
        onExit: ($element: HTMLElement) => {
          $element?.parentElement
            ?.querySelectorAll('.js-navi-item')
            [i]?.classList.remove(toggleClassName);
        },
      });
    });
  }
};

handleNavigationClicks();
