window.addEventListener('load', () => {
  const items = document.querySelectorAll('.process__timeline-item');
  const stickyWrapper = document.querySelector(
    '.process .sticky-wrapper',
  ) as HTMLElement;
  /* const timeline = document.querySelector('.process__timeline') as HTMLElement; */
  const index = document.querySelector('.process__timeline-item-index');
  const processWrapper = document.querySelector(
    '.process .panel-wrapper',
  ) as HTMLElement;

  if (!items.length || !stickyWrapper || !processWrapper) {
    return;
  }

  const updateScrolling = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const top30Percent = windowHeight * 0.3;

    const processTop = processWrapper.getBoundingClientRect().top;
    const processHeight = processWrapper.getBoundingClientRect().height;
    const processOutOfView = processHeight - windowHeight;

    if (processTop <= top30Percent) {
      const scrollProgress = Math.min(
        1,
        (top30Percent - processTop) / processOutOfView,
      );
      const newTop = 30 + scrollProgress * 30;
      stickyWrapper.style.top = `${newTop}%`;
    }
    if (processHeight < windowHeight) {
      if (windowWidth < 768) {
        stickyWrapper.style.top = '20%';
      } else {
        stickyWrapper.style.top = '30%';
      }
    }
  };

  // Set height of processWrapper so the sticky wrapper stops at the last item
  const stickyWrapperHeight = stickyWrapper.clientHeight;
  processWrapper.style.height = `${
    processWrapper.clientHeight - stickyWrapperHeight * 1.1
  }px`;

  // Remove line before first item and after last item
  if (items.length > 0) {
    const firstItem = items[0];
    const firstItemIndex = firstItem.querySelector(
      '.process__timeline-item-index',
    );
    if (firstItemIndex) {
      firstItemIndex.classList.add('no-height-before');
    }
  }

  if (items.length > 1) {
    const lastItem = items[items.length - 1];
    const lastItemIndex = lastItem.querySelector(
      '.process__timeline-item-index',
    );
    if (lastItemIndex) {
      lastItemIndex.classList.add('no-height-after');
    }
  }

  const calculateIntersection = (element: Element | null) => {
    if (element && stickyWrapper) {
      const rect = element.getBoundingClientRect();
      const stickyRect = stickyWrapper.getBoundingClientRect();
      const stickyTop = stickyRect.top;
      const stickyBottom = stickyRect.bottom;
      const intersectionTop = Math.max(rect.top, stickyTop);
      const intersectionBottom = Math.min(rect.bottom, stickyBottom);
      const intersection = Math.max(0, intersectionBottom - intersectionTop);
      return intersection;
    }
    return 0;
  };

  let mostInsideItem: Element | null = null;

  const activateItem = () => {
    let maxIntersection = 0;
    mostInsideItem = null;

    items.forEach((item) => {
      const intersection = calculateIntersection(item);
      if (intersection > maxIntersection) {
        maxIntersection = intersection;
        mostInsideItem = item;
      }
      const imageWrapper = item.querySelector(
        '.process__timeline-image-overline-wrapper',
      ) as HTMLElement;
      const indexWidth = index?.clientWidth;
      if (indexWidth && imageWrapper) {
        imageWrapper.style.paddingLeft = `${indexWidth + 2}px`;
      }
    });

    items.forEach((item) => {
      // Deactivate all elements
      item.classList.remove('active');
    });
    // Activate the most inside item
    if (mostInsideItem) {
      (mostInsideItem as Element).classList.add('active');

      // To-Do : update stickyWrapper height based on mostInsideItem height

      /* const itemHeight = mostInsideItem.getBoundingClientRect().height;

      if (window.innerWidth < 768 && timeline) {
        if (itemHeight > 300) {
          stickyWrapper.style.transition = 'height 0.3s';
          stickyWrapperHeight = itemHeight * 1.1;
          stickyWrapper.style.height = `${itemHeight * 1.1}px`;
          timeline.style.transform = `translateY(-${
            stickyWrapperHeight * 1.05
          }px)`;
        } else if (itemHeight < 300) {
          stickyWrapper.style.transition = 'height 0.3s';
          stickyWrapper.style.height = '280px';
          timeline.style.transform = `translateY(-320px)`;
        }
      } */
    } else {
      console.warn('No item found to activate');
    }
  };

  window.addEventListener('scroll', () => {
    updateScrolling();
    activateItem();
  });
  window.addEventListener('resize', () => {
    activateItem();
  });
  activateItem();
});
