class BackToTopClass {
  constructor($el: HTMLElement) {
    this.$backToTopButton = $el;
    this.initEvents();
  }

  $backToTopButton: HTMLElement;

  initEvents = () => {
    this.$backToTopButton.addEventListener('click', () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });

    if (this.$backToTopButton) {
      let previousScrollPosition = window.scrollY;

      window.addEventListener('scroll', () => {
        const currentScrollPosition = window.scrollY;
        const viewportHeight = window.innerHeight;

        if (
          currentScrollPosition > viewportHeight &&
          currentScrollPosition < previousScrollPosition
        ) {
          // If scrolling up, add the class
          this.$backToTopButton.classList.add('is-visible');
        } else {
          // If not scrolled enough, remove the class
          // If scrolling down, remove the class
          this.$backToTopButton.classList.remove('is-visible');
        }

        previousScrollPosition = currentScrollPosition;
      });
    }
  };
}

document.querySelectorAll('.back-to-top').forEach((el) => {
  new BackToTopClass(el);
});
