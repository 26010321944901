const redirectField = document.querySelector('#passwordRedirectUrl');

/**
 * @param cname
 */
function getCookie(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function checkCookie() {
  return getCookie('nBM_validated') === 'true';
}

if (redirectField) {
  if (!checkCookie()) {
    document.location.href = redirectField.value;
  } else {
    document.querySelector('.redirect-overlay')?.classList.add('hidden');
  }
}
