import { on } from 'delegated-events';
import { register } from 'swiper/element/bundle';
import Swiper from 'swiper';

// Pagination
on('click', '.pagination a', () => {
  document.querySelectorAll('#news-number')[0].scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
});

// Register Swiper
register();

const initSliders = () => {
  const swiperContainers = document.querySelectorAll('.swiper-container');

  swiperContainers.forEach((container) => {
    const initialSlideIndex =
      parseInt(container.getAttribute('data-initial-slide'), 10) || 0;

    const nextButton = container.querySelector('.custom-button-next');
    const prevButton = container.querySelector('.custom-button-prev');
    const pagination = container.querySelector('.swiper-pagination');
    let centered = false;

    if (initialSlideIndex > 1) {
      centered = true;
    }

    // eslint-disable-next-line no-new
    new Swiper(container, {
      centeredSlides: centered,
      initialSlide: initialSlideIndex,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      slidesPerView: 'auto',
      spaceBetween: 16,
    });
  });
};

export default initSliders;

(window as any).initSliders = initSliders;

initSliders();
