import { on } from 'delegated-events';

function morphSvgator() {
  // svgator morph script
  (function (s, i, o, w, d, a, b) {
    (a =
      Array.from(d.querySelectorAll(`svg#${i.root}`)).filter(
        (n) => !n.svgatorPlayer,
      )[0] || {}).svgatorPlayer = {
      ready: (function (a) {
        b = [];
        return function (c) {
          return c ? (b.push(c), a.svgatorPlayer) : b;
        };
      })(a),
    };
    w[o] = w[o] || {};
    w[o][s] = w[o][s] || [];
    w[o][s].push(i);
  })(
    '91c80d77',
    {
      root: 'es1tlNDkdBb1',
      version: '2025-02-04',
      animations: [
        {
          elements: {
            es1tlNDkdBb2: {
              d: [
                {
                  t: 400,
                  v: [
                    'M',
                    162,
                    55,
                    'L',
                    187.5,
                    81,
                    'L',
                    171.5,
                    98,
                    'L',
                    97,
                    98,
                    'C',
                    91,
                    109.5,
                    78,
                    122,
                    60,
                    122,
                    'C',
                    32.5,
                    122,
                    18,
                    100.5,
                    18,
                    81,
                    'C',
                    18,
                    61.5,
                    32.5,
                    40,
                    60,
                    40,
                    'C',
                    82.5,
                    40,
                    94,
                    58,
                    97.5,
                    66.5,
                    'L',
                    113.5,
                    66.5,
                    'C',
                    113.5,
                    66.5,
                    129,
                    55,
                    129,
                    55,
                    'C',
                    129,
                    55,
                    146,
                    68,
                    146,
                    68,
                    'L',
                    162,
                    55,
                    'Z',
                  ],
                },
                {
                  t: 500,
                  v: [
                    'M',
                    165.722078,
                    75.345946,
                    'L',
                    179.8125,
                    94.336487,
                    'L',
                    157.9375,
                    107.086487,
                    'L',
                    102.75,
                    94.336487,
                    'C',
                    102.75,
                    94.336487,
                    67.0625,
                    113.818111,
                    67.0625,
                    113.818111,
                    'C',
                    67.0625,
                    113.818111,
                    35.53158,
                    99.330866,
                    35.53158,
                    99.330866,
                    'C',
                    35.53158,
                    99.330866,
                    35.53158,
                    75.345946,
                    35.53158,
                    75.345946,
                    'C',
                    35.53158,
                    75.345946,
                    57.1875,
                    55.086487,
                    57.1875,
                    55.086487,
                    'L',
                    82.937903,
                    50.863208,
                    'C',
                    82.937903,
                    50.863208,
                    122.1875,
                    45.211487,
                    122.1875,
                    45.211487,
                    'C',
                    122.1875,
                    45.211487,
                    146.618038,
                    55.086487,
                    146.618038,
                    55.086487,
                    'L',
                    165.722078,
                    75.345946,
                    'Z',
                  ],
                },
                {
                  t: 1000,
                  v: [
                    'M',
                    156.75,
                    55.345946,
                    'L',
                    156.75,
                    134.345946,
                    'L',
                    117.25,
                    134.345946,
                    'L',
                    117.25,
                    89.272443,
                    'C',
                    117.25,
                    89.272443,
                    88.25,
                    89.272443,
                    88.25,
                    89.272443,
                    'C',
                    88.25,
                    89.272443,
                    88.25,
                    134.345946,
                    88.25,
                    134.345946,
                    'C',
                    88.25,
                    134.345946,
                    48.75,
                    134.345946,
                    48.75,
                    134.345946,
                    'C',
                    48.75,
                    134.345946,
                    48.75,
                    55.345946,
                    48.75,
                    55.345946,
                    'L',
                    56.050735,
                    49.801872,
                    'C',
                    56.050735,
                    49.801872,
                    101.75,
                    15.845946,
                    101.75,
                    15.845946,
                    'C',
                    101.75,
                    15.845946,
                    149.094488,
                    49.801872,
                    149.094488,
                    49.801872,
                    'L',
                    156.75,
                    55.345946,
                    'Z',
                  ],
                  e: [0, 0, 0.295, 1],
                },
              ],
            },
            es1tlNDkdBb3: {
              transform: {
                data: { o: { x: 59.347835, y: 86.5, type: 'corner' } },
                keys: {
                  s: [
                    { t: 0, v: { x: 1, y: 1 } },
                    { t: 500, v: { x: 0, y: 0 }, e: [0.42, 0, 0.58, 1] },
                  ],
                },
              },
            },
          },
          s: 'MDIA1ZDhiUTMyNTzQ4NTgyNzE4ENDc5UDdmN2UDzMjRhNDM0MDQQwWDQwM2MzMMjc0Nzk4Mjc1YNzM4NDc5N2YV3ZTMyNGE0MTSNjMzI3OTg0NHzU4MjcxODQ3UOVI3ZjdlODMEzMjRhNDEzY1BEzMjc2Nzk3YAzdjMzJKNGE0QMTNjMzI3MTdHjODQ3NTgyN2XU3MTg0NzUzMWjRhVzc2NzE3MYzgzNzUzY08WzMjgzODA3NTRc1NzQzMjRhNYDEzYzMyNzY4FMDgzMzJVNGEO0MTQwQzQwOGIQ/',
        },
      ],
      options: 'MDKAxMDgyUzI5NB2E3YjY4Nzk3BYjI5NDEyOTcUzNzZVNjg2YjYI5ODQ/',
    },
    '__SVGATOR_PLAYER__',
    window,
    document,
  );
}

function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return function debounceFunction(this: any, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

const field: HTMLInputElement | null = document.querySelector('#passwordField');
const passwordArea = document.querySelector('.password-area');
const step1 = document.querySelector('.password-area__step1');
const step2 = document.querySelector('.password-area__step2');

function isPasswordValid() {
  const passwordField: HTMLInputElement | null = document.querySelector(
    '#superSecretPassword',
  );
  return !!(field && passwordField && field.value === passwordField.value);
}

/**
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie(cname: string, cvalue: boolean, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

/**
 * @param cname
 */
function getCookie(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function isCookieSet() {
  return getCookie('nBM_validated') === 'true';
}

function transitionStep2() {
  step1?.classList.add('fade-out');
  setTimeout(() => {
    morphSvgator();
  }, 200);
  setTimeout(() => {
    step1?.classList.remove('visible');
    step2?.classList.add('visible');
    setTimeout(() => {
      step2?.classList.add('fade-in');
      document
        .querySelector('.password-area__appendix')
        ?.classList.add('step2');
    }, 50);
  }, 1400);
}

function init() {
  // Check if user already unlocked in the last 14 days
  if (isCookieSet()) {
    step1?.classList.add('visible');
    setTimeout(() => {
      passwordArea?.scrollIntoView();
      transitionStep2();
    }, 200);
  } else {
    step1?.classList.add('visible');

    const scrollToPasswordArea = () => {
      if (passwordArea) {
        passwordArea?.scrollIntoView();
        const rect = passwordArea.getBoundingClientRect();

        // workaround firefox that also works with chromium browsers, which have a other scroll-to behavior
        if (rect.top >= -100 && rect.top <= 0) {
          document.body.style.overflow = 'hidden';
          window.removeEventListener('scroll', scrollToPasswordArea);
          setTimeout(() => {
            document.body.style.overflow = '';
          }, 1000);
        }
      }
      return false;
    };
    window.addEventListener('scroll', scrollToPasswordArea);

    // workaround firefox inertial mouse scrolling bug
    // first: force-scroll to password area on every scroll event
    // as soon as the browser stops scrolling for 100ms, unbind the force-scroll
    window.addEventListener(
      'scroll',
      debounce(
        () => window.removeEventListener('scroll', scrollToPasswordArea),
        100,
      ),
    );
  }
}

if (field) {
  on('click', '.eyecon', () => {
    const eyecons: NodeListOf<HTMLElement> =
      document.querySelectorAll('.eyecon');
    [].forEach.call(eyecons, (eyecon: HTMLElement) => {
      eyecon?.classList.toggle('visible');
    });
    if (field?.type === 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  });

  on('click', '#btnUnlock', () => {
    passwordArea?.scrollIntoView({ behavior: 'smooth' });

    if (isPasswordValid()) {
      transitionStep2();

      setCookie('nBM_validated', true, 14);
    } else {
      field.classList.add('error');
    }
  });

  on('click', '.password-area__link', (e) => {
    e.currentTarget?.classList.add('active');
    e.currentTarget.parentElement
      ?.querySelector('.password-area__link:not(.active)')
      ?.classList.add('inactive');

    passwordArea?.scrollIntoView({ behavior: 'smooth' });
    passwordArea?.classList.add('checkout');
  });

  init();
}
